var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-skeleton-loader',{staticClass:"my-2",attrs:{"loading":_vm.loading,"boilerplate":"","height":"150","transition":"scale-transition","type":"article"}},[(!_vm.$vuetify.breakpoint.mobile)?_c('div',{staticClass:"rounded-lg mx-1 my-4 pa-5 white--text",class:_vm.part.partsVisible === 1 ? 'grey darken-3' : 'grey darken-1'},[_c('v-row',[_c('v-col',{attrs:{"cols":"1"}},[(!_vm.imageLoading)?_c('v-img',{attrs:{"src":_vm.imageName,"width":"100px"}}):_vm._e()],1),_c('v-col',[_c('h2',[_vm._v(_vm._s(_vm.part.partsName))]),_c('h4',[_vm._v(_vm._s(_vm.part.partsDesc))])]),_c('v-col',{attrs:{"md":"auto"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.$router.push({
              name: 'Part',
              query: { id: _vm.part.partsID, name: _vm.part.partsName },
            })}}},[_vm._v("View Part ")]),_c('v-icon',{staticClass:"white--text mx-3",on:{"click":function($event){return _vm.$router.push({ name: 'PartEdit', params: { id: _vm.part.partsID } })}}},[_vm._v("mdi-pencil ")]),_c('v-icon',{staticClass:"white--text mx-3",on:{"click":function($event){_vm.sheet = !_vm.sheet}}},[_vm._v("mdi-delete ")])],1)],1),_c('v-bottom-sheet',{model:{value:(_vm.sheet),callback:function ($$v) {_vm.sheet=$$v},expression:"sheet"}},[_c('v-sheet',{staticClass:"text-center",attrs:{"height":"400px"}},[_c('v-btn',{staticClass:"mt-6",attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.sheet = !_vm.sheet}}},[_vm._v(" close ")]),_c('div',{staticClass:"py-3 mx-12"},[_c('h2',{staticClass:"pa-2 primary--text"},[_vm._v("CAUTION")]),_c('div',{staticClass:"d-inline-flex"},[_c('h3',[_vm._v(" You are about to delete this part, this cannot be done once you confirm below. ")])])]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.confirmDeletePart}},[_vm._v("Delete")])],1)],1)],1):_vm._e(),(_vm.$vuetify.breakpoint.mobile)?_c('div',{staticClass:"grey darken-3 rounded-lg mx-1 my-4 pa-5 white--text"},[_c('v-row',[_c('v-col',[(!_vm.imageLoading)?_c('v-img',{attrs:{"src":_vm.imageName,"height":"200px","width":"auto"}}):_vm._e()],1),_c('v-col',[_c('h2',[_vm._v(_vm._s(_vm.part.partsName))]),_c('h4',[_vm._v(_vm._s(_vm.part.partsDesc))]),_c('v-btn',{on:{"click":function($event){return _vm.$router.push({
              name: 'Part',
              query: { id: _vm.part.partsID, name: _vm.part.partsName },
            })}}},[_vm._v("View Part ")]),_c('br',{staticClass:"my-4"}),_c('v-icon',{staticClass:"white--text mx-3",on:{"click":function($event){return _vm.$router.push({ name: 'PartEdit', params: { id: _vm.part.partsID } })}}},[_vm._v("mdi-pencil ")]),_c('v-icon',{staticClass:"white--text mx-3",on:{"click":function($event){_vm.sheet = !_vm.sheet}}},[_vm._v("mdi-delete ")])],1)],1),_c('v-bottom-sheet',{model:{value:(_vm.sheet),callback:function ($$v) {_vm.sheet=$$v},expression:"sheet"}},[_c('v-sheet',{staticClass:"text-center",attrs:{"height":"400px"}},[_c('v-btn',{staticClass:"mt-6",attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.sheet = !_vm.sheet}}},[_vm._v(" close ")]),_c('div',{staticClass:"py-3 mx-12"},[_c('h2',{staticClass:"pa-2 primary--text"},[_vm._v("CAUTION")]),_c('div',{staticClass:"d-inline-flex"},[_c('h3',[_vm._v(" You are about to delete this part, this cannot be done once you confirm below. ")])])]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.confirmDeletePart}},[_vm._v("Delete")])],1)],1)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }