<template>
  <v-skeleton-loader
      :loading="loading"
      boilerplate
      class="my-2"
      height="150"
      transition="scale-transition"
      type="article"
  >
    <div
        v-if="!$vuetify.breakpoint.mobile"
        class="rounded-lg mx-1 my-4 pa-5 white--text"
        :class="part.partsVisible === 1 ? 'grey darken-3' : 'grey darken-1'"
    >
      <v-row>
        <v-col cols="1">
          <v-img
              v-if="!imageLoading"
              :src="imageName"
              width="100px"
          />
        </v-col>
        <v-col>
          <h2>{{ part.partsName }}</h2>
          <h4>{{ part.partsDesc }}</h4>
        </v-col>
        <v-col md="auto">
          <v-btn
              color="primary"
              @click="
              $router.push({
                name: 'Part',
                query: { id: part.partsID, name: part.partsName },
              })
            "
          >View Part
          </v-btn
          >

          <v-icon
              class="white--text mx-3"
              @click="
              $router.push({ name: 'PartEdit', params: { id: part.partsID } })
            "
          >mdi-pencil
          </v-icon
          >
          <v-icon class="white--text mx-3" @click="sheet = !sheet"
          >mdi-delete
          </v-icon
          >
        </v-col>
      </v-row>
      <v-bottom-sheet v-model="sheet">
        <v-sheet class="text-center" height="400px">
          <v-btn class="mt-6" color="primary" text @click="sheet = !sheet">
            close
          </v-btn>
          <div class="py-3 mx-12">
            <h2 class="pa-2 primary--text">CAUTION</h2>
            <div class="d-inline-flex">
              <h3>
                You are about to delete this part, this cannot be done once you
                confirm below.
              </h3>
            </div>
          </div>
          <v-btn color="primary" @click="confirmDeletePart">Delete</v-btn>
        </v-sheet>
      </v-bottom-sheet>
    </div>

    <!-- Mobile -->
    <div
        v-if="$vuetify.breakpoint.mobile"
        class="grey darken-3 rounded-lg mx-1 my-4 pa-5 white--text"
    >
      <v-row>
        <v-col>
          <v-img
              v-if="!imageLoading"
              :src="imageName"
              height="200px"
              width="auto"
          />
        </v-col>
        <v-col>
          <h2>{{ part.partsName }}</h2>
          <h4>{{ part.partsDesc }}</h4>
          <v-btn
              @click="
              $router.push({
                name: 'Part',
                query: { id: part.partsID, name: part.partsName },
              })
            "
          >View Part
          </v-btn
          >
          <br class="my-4"/>
          <v-icon
              class="white--text mx-3"
              @click="
              $router.push({ name: 'PartEdit', params: { id: part.partsID } })
            "
          >mdi-pencil
          </v-icon
          >
          <v-icon class="white--text mx-3" @click="sheet = !sheet"
          >mdi-delete
          </v-icon
          >
        </v-col>
      </v-row>
      <v-bottom-sheet v-model="sheet">
        <v-sheet class="text-center" height="400px">
          <v-btn class="mt-6" color="primary" text @click="sheet = !sheet">
            close
          </v-btn>
          <div class="py-3 mx-12">
            <h2 class="pa-2 primary--text">CAUTION</h2>
            <div class="d-inline-flex">
              <h3>
                You are about to delete this part, this cannot be done once you
                confirm below.
              </h3>
            </div>
          </div>
          <v-btn color="primary" @click="confirmDeletePart">Delete</v-btn>
        </v-sheet>
      </v-bottom-sheet>
    </div>
  </v-skeleton-loader>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  props: ["part"],
  data() {
    return {
      sheet: false,
      images: [],
      loading: true,
      imageLoading: true,
    };
  },
  computed: {
    ...mapState(["baseURL"]),
    imageName() {
      return (
          this.baseURL +
          "/storage/partImgs/" +
          this.images[0]["partsImgStr"].split(".webp")[0] +
          "Lazy.webp"
      );
    },
  },
  mounted() {
    if (this.part !== {}) {
      this.getImages();
    }
    if (this.part !== undefined || this.part !== {}) {
      this.loading = false;
    }
  },
  methods: {
    ...mapActions("parts", ["deletePart"]),
    ...mapActions("data", ["postData"]),
    async confirmDeletePart() {
      let response = await this.deletePart({
        partsID: this.part.partsID,
      });
      if (response["error"] !== "none") {
        alert("An error has occurred");
      } else if (response["error"] === "none") {
        this.$emit("deletedPart");
      }
    },
    async getImages() {
      let response = await this.postData({
        url: "fetchPartImg",
        data: {id: this.part.partsID},
      });
      if (response["error"] !== "none") {
        console.error("Error: " + response["error"]);
      } else {
        this.images = response["images"];
        this.imageLoading = false;
      }
    },
  },
};
</script>
