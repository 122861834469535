<template>
  <div>
    <v-row>
      <v-col></v-col>
      <v-col cols="9">
        <h1 class="text-h2 text-uppercase font-weight-bold my-6">My Stuff</h1>
        <div
            :class="$vuetify.breakpoint.mobile ? 'flex-column' : 'd-inline-flex'"
        >
          <h5 class="text-h5">
            {{ usedParts }} out of {{ totalParts }} parts used
          </h5>
          <div class="d-inline-flex">
            <h6
                v-show="usedPartsPercent > 85"
                class="text-h6 mx-6 primary--text"
            >
              {{ usedPartsPercent }}%
            </h6>
            <v-btn
                v-show="usedPartsPercent > 85"
                :to="{ name: 'Subscriptions' }"
                color="primary"
            >Upgrade plan
            </v-btn
            >
          </div>
        </div>
        <v-text-field
            v-model="search"
            class="mt-3 mb-n3"
            label="Search list"
            placeholder="Enter Part's name"
            solo
        />
        <div v-show="hasParts">
          <div v-if="usedParts/totalParts >= 1">
            <v-btn :to="{ name: 'Subscriptions' }" class="white--text" color="primary"
            >Upgrade Plan
            </v-btn
            >
          </div>
          <div v-else>
            <v-btn :to="{ name: 'NewPart' }" class="white--text" color="primary"
            >Create New Part
            </v-btn
            >
          </div>
        </div>
        <div v-show="!hasParts">
          <h2 class="my-3">
            You currently have no parts created, you can create one here...
          </h2>
          <v-btn :to="{ name: 'NewPart' }" class="white--text" color="primary"
          >Create New Part
          </v-btn
          >
        </div>
        <div>
          <PartListItem
              v-for="part in filteredParts"
              :key="part.partsID"
              :part="part"
              @deletedPart="deleted()"
          />
        </div>
      </v-col>
      <v-col></v-col>
    </v-row>
  </div>
</template>

<script>
import PartListItem from "@/components/PartListItem.vue";
import {mapActions, mapState} from "vuex";
// This page is for a user to change information about a part, so kinda like a listed view or something of each user's parts
export default {
  emits: ["deletedPart"],
  components: {PartListItem},
  metaInfo: {
    title: "My Parts",
    "http-equiv": "Content-Security-Policy",
    content: "upgrade-insecure-requests",
  },
  data() {
    return {
      parts: [],
      usedParts: 0,
      totalParts: 0,
      hasParts: true,
      search: "",
    };
  },
  mounted() {
    this.pwdCheckCall();
    this.getParts();
    this.getPartCount();
  },
  computed: {
    ...mapState("user", ["currentUser"]),
    usedPartsPercent() {
      return (this.usedParts / this.totalParts) * 100;
    },
    filteredParts() {
      return this.parts.filter((part) => {
        const re = new RegExp(this.search, "gi");
        return part.partsName.match(re);
      });
    },
  },
  methods: {
    ...mapActions("alert", ["alerts"]),
    ...mapActions("user", ["pwdCheck"]),
    ...mapActions("data", ["postData"]),
    async pwdCheckCall() {
      let response = await this.pwdCheck();
      if (response["error"] === "userNotFound") {
        this.alerts({
          type: "error",
          msg: "We had trouble finding the user, make sure you are logged in, or login again",
        });
      } else if (response["error"] === "stmtFailed") {
        this.alerts({
          type: "error",
          msg: "It seems something has failed on our end, try again or at a later time",
        });
      } else if (response["error"] === "noPostData") {
        this.alerts({
          type: "error",
          msg: "There was an issue sending data, please try again or login again",
        });
      }
      if (response["correct"] === true) {
      } else if (response["correct"] === false) {
        await this.$router.push({name: "Login"});
        this.alerts({
          type: "error",
          msg: "You must be logged in to access this page.",
        });
      }
    },
    deleted() {
      this.parts = [];
      this.getParts();
      this.getPartCount();
    },
    async getParts() {
      let response = await this.postData({
        url: "fetchAllUserParts",
        data: {usersID: this.currentUser.usersID},
      });
      if (response["error"] === "none") {
        if (response["parts"] === "none") {
          this.hasParts = false;
        } else {
          this.parts = response["parts"];
        }
      } else if (response["error"] !== "none") {
        console.error("Error" + response["error"]);
      }
    },
    async getPartCount() {
      let response = await this.postData({
        url: "fetchUserTier",
        data: {usersID: this.currentUser.usersID},
      });
      if (response["error"] === "none") {
        this.usedParts = response["usersPartsAmount"];
        if (response["usersTier"] === 0) {
          this.totalParts = 25;
        } else if (response["usersTier"] === 1) {
          this.totalParts = 150;
        } else if (response["usersTier"] === 2) {
          this.totalParts = 1000;
        } else if (response["usersTier"] === 3) {
          this.totalParts = 10000;
        }
      } else if (response["error"] !== "none") {
        console.error("Error" + response["error"]);
      }
    },
  },
};
</script>

<style>
</style>